/* Page transition animation */

.slide-enter-active,
.slide-exit-active {
  transition: transform 2000ms ease;
}

.slide-enter {
  transform: translateY(100%)
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  margin-right: 20px;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
}

@keyframes menuin {
  0% {
    visibility: hidden;
    height: 0px;
  }
  50% {
    max-height: 150px;
    visibility: hidden;
  }
  99% {
    max-height: 300px;
    visibility: hidden;
  }
}

@keyframes menuout {
  0% {
    visibility: hidden;
    max-height: 300px;
  }
  50% {
    max-height: 150px;
  }
}
