.screenshot-modal {
  @include flexy($aligni: center, $just: center);
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  img {
    min-width: 10vw;
    max-height: 80%;
  }
}
