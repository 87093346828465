.contact-container {
  @include flexy($just: space-around, $aligni: baseline);
  min-width: 400px;
  max-width: 875px;
  height: fit-content;
  background: $element-background;
  padding: 50px 20px 50px 25px;
  flex: 1;

  h3 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    text-decoration: underline;
  }

  a:hover {
    color: lightskyblue;
  }
}

.ui.form {
  min-width: 340px;
  max-width: 500px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  font-size: 1rem;
  position: relative;
  margin-left: 20px;

  .field {
    clear: both;
    margin: 0 0 1em;
    width: auto;

    label {
      color: $white;
      font-weight: 500;
    }
  }

  textarea {
    font-family: 'Open Sans', sans-serif;
    text-indent: 5px;
    padding: 0;
    border: none;
    height: 250px;
  }
}

.ui.green.button {
  background-color: #198f35;
  color:$white;
  box-sizing: content-box;
  padding: 11px 21px 11px 21px;
  border-radius: 4px;
  border-color: $white;
  border-width: 0;

  &:hover {
    background-color: #16ab39;
  }
}