/* ===================================== 
   Grid Layout
======================================== */

.container {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 200px minmax(340px, 1280px);
  gap: 20px;
  grid-template-areas: "aside main";
}

.home-grid {
  display: grid;
  grid-template-columns: minmax(514px, 1280px);
  grid-template-rows: 80px;
  gap: 20px;
  grid-template-areas:
    "main-header"
    "home-text";
}

.project-grid {
  display: grid;
  grid-template-columns: minmax(550px, 1280px);
  grid-template-rows: minmax(80px, 95px);
  gap: 20px;
  grid-template-areas:
    "project-header"
    "project-wrapper";
}

.project-gallery-grid {
  display: grid;
  grid-template-columns: minmax(340px, 1280px);
  grid-template-rows: 80px;
  row-gap: 20px;
  grid-template-areas:
    "project-gallery-header"
    "project-gallery";
}
