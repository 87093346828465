.home-text-container {
  grid-area: home-text;
  @include flexy($aligni: baseline);
  min-width: 400px;
  max-width: 870px;
  background: $element-background;
  padding: 20px 25px 20px 25px;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
